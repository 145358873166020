import logo from './logo.svg';
import './App.css';
import React from 'react';
import ReactHtmlParser from 'react-html-parser';
import TextField from '@mui/material/TextField';
import Button from "@mui/material/Button"
import CircularProgress from '@mui/material/CircularProgress'
import EmptyIcon from '@mui/icons-material/Anchor'
import Snackbar from '@mui/material/Snackbar';
import Typography from '@mui/material/Typography';
import kraken from './kraken.jpg'

class App extends React.Component {

  state = {
    url: '-',
    loading: false,
    content: null, // '<div>Example HTML string</div>',
    error: null,
  }

  handleClick = (e) => {
    let { url } = this.state
    // Set to loading
    this.setState({ loading: true })

    // Fix url
    if(!url.startsWith('http')) {
      url =   `http://${url}`
    }
    console.log(`url ${url}`)

    // Circumvent crossorigin 
    const anyorigin = `https://api.allorigins.win/get?url=${encodeURIComponent(url)}`
    // url = 'https://www.nytimes.com/live/2021/09/30/us/government-shutdown-infrastructure'

    
    // Go get the url and set the inner content
    fetch(anyorigin,{
      referrer: 'http://google.com',
      'Cache-Control': 'no-cache',
      'Pragma': 'no-cache',
      'Expires': '0',
      credentials: 'omit',
      "User-agent": "Mozilla/5.0 (compatible; Googlebot/2.1; +http://www.google.com/bot.html)",

    })
      .then(res => {
      debugger;
        if (res.ok) { return res.json() }
        throw new Error('Network response was not ok.')
      })
      .then(json => {
        // debugger;
        const content = json.contents
        this.setState({ content: content })
        console.log('good')
      })
      .catch(e => {
        console.error(e)
        this.setState({error:e})
        console.log('bad')
      })
      .finally(() => {
        // debugger;
        this.setState({ loading: false })
      })
  }
  handleInput = e => {
    console.log(e.target.value)
    this.setState({ url: e.target.value })
  }
  handleKeyPress = e => {
    console.log(`key pressed ${e.key}`)
    if (e.key == 'Enter') {
      this.handleClick()
    }
  }
  render() {
    const { content, loading, url } = this.state
    return (
      <div className="App">
        <img src={kraken} style={{objectFit: "cover", top:0, right:0, zIndex: "-999", opacity:"10%", width:"100%", height: "100%", position:"absolute"}} alt="Logo" />

        <header className="App-header">
          <TopContainer
            url={url}
            handleInput={this.handleInput}
            handleClick={this.handleClick}
            handleKeyPress={this.handleKeyPress}
          />
        </header>
        <ContentContainer content={content} loading={loading} />
      </div>
    );
  }
}

class TopContainer extends React.Component {
  render() {
    const { url, handleClick, handleInput, handleKeyPress } = this.props
    return (
      <div className="top-container">
        <TextField 
          autoFocus
          onKeyPress={handleKeyPress}
          className="input-container"
          onChange={handleInput}
          id="standard-basic"
          label="https://..." variant="standard" 
        />
        <Button
          variant="outlined"
          className="button-container"
          onClick={handleClick}>
          go
        </Button>
      </div>
    )
  }
}
function ContentContainer({ content, loading }) {

  // const html = content && ReactHtmlParser(content)
  return (
    <div className="content-container">
      {loading
        ? <div style={{display:"flex", height: "100%", alignItems:"center", justifyContent:"center"}}>
          <div style={{display:"flex", flexDirection: "column", alignItems:"center"}}>
            <CircularProgress style={{margin: "8px"}} />
            <Typography variant="h5">
              Paying the iron price ...
            </Typography>
          </div>
          </div>
        : content
          ? <iframe title="iron" style={{width:'100%', height:"100%"}} sandbox srcdoc={content} />
          : <Empty />
      }
    </div>

  )
}

function Empty() {
  return (
    <div style={{display:'flex', alignItems: 'center', height:'100%', width:"100%", justifyContent:'center'}}>  
      <EmptyIcon style={{width:'20vw', height:'20vh'}} />
    </div>
  )
}

export default App;
